import React from 'react'

function Dashboard() {
  return (
    <div className='container-home  bg-parents'>
      <h1>Panel de Administración</h1>
      </div>
  )
}

export default Dashboard