import React from 'react'

function Spinner() {
  return (
    <div class="loadingio-spinner-spin-mlsrd4oslk"><div class="ldio-plydfyvlazq">
    <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
    </div></div>
  )
}

export default Spinner